import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

const CallToActionBanana = () => {
    return (
        <div className="bg-banana" id="bg-banana">
        </div>
    );
}

export default CallToActionBanana;
