import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Container,
  Row,
  Col,
} from "reactstrap";

import product1 from "../../../assets/images/musarium/product1.jpg";
import product2 from "../../../assets/images/musarium/product2.jpg";
import product3 from "../../../assets/images/musarium/product3.jpg";
const BrandStory = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="spacer" id="js-component">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h1 className="title font-bold">{t("brand-story")}</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="m-b-70">
          <Col md="6" xl="4" className="m-b-30 text-center ">
            <img src={product2} alt="img" className="img-stroy img-rounded" />
          </Col>
          <Col md="6" xl="8" className="title-story">
            <h4 className="">{t("title-1")}</h4>
            <h6 className="subtitle-background">{t("desc-1")}</h6>
          </Col>
        </Row>
        <Row className="m-b-70">
          <Col md="6" xl="4" className="m-b-30 text-center ">
            <img src={product3} alt="img" className="img-stroy img-rounded" />
          </Col>
          <Col md="6" xl="8" className="title-story">
            <h4 className="">{t("title-2")}</h4>
            <h6 className="subtitle-background">{t("desc-2")}</h6>
          </Col>
        </Row>
        <Row className="m-b-70">
          <Col md="6" xl="4" className="m-b-30 text-center ">
            <img src={product1} alt="img" className="img-stroy img-rounded" />
          </Col>
          <Col md="6" xl="8" className="title-story">
            <h4 className="">{t("title-3")}</h4>
            <h6 className="subtitle-background">{t("desc-3")}</h6>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BrandStory;
