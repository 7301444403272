import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Container,
  Row,
  Col,
} from "reactstrap";
import Section from "./section";
import logo from "../../../assets/images/musarium/logo-green.png";

const BrandMessage = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="spacer" id="js-component">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <img
                src={logo}
                alt="img"
                className="img-logo-green"
  
              />
              <h1 className="title font-bold">{t("brand-message")}</h1>
              <h2 className="">{t("key-word-message")}</h2>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="justify-content-center">
          <Col md="12" xl="4" className="col-message">
            <div>
              <div className="img-section-1"></div>
              <div className="bg-section">
                <h6 className="title-message">{t("title-message-1")}</h6>
                <h6 className="subtitle-message">{t("desc-message-1")}</h6>
              </div>
            </div>
          </Col>
          <Col md="12" xl="4" className="col-message">
            <div>
              <div className="img-section-2"></div>
              <div className="bg-section">
                <h6 className="title-message">{t("title-message-2")}</h6>
                <h6 className="subtitle-message">{t("desc-message-2")}</h6>
              </div>
            </div>
          </Col>
          <Col md="12" xl="4" className="col-message">
            <div>
              <div className="img-section-3"></div>
              <div className="bg-section">
                <h6 className="title-message">{t("title-message-3")}</h6>
                <h6 className="subtitle-message">{t("desc-message-3")}</h6>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BrandMessage;
