import React from "react";
import { useTranslation } from "react-i18next";
import { HashLink as Link } from "react-router-hash-link";
import { Container, Row, Col } from "reactstrap";
import logo from "../../../src/assets/images/musarium/logo-white.png";
import product_header from "../../../src/assets/images/musarium/product-head.png";
const HeaderBanner = () => {
  const { t } = useTranslation();
  return (
    <div className="static-slider-head">
      <Container>
        <Row className="justify-content-center">
          <Col lg="8" md="6" className="align-self-center text-center">
            <img src={logo} alt="img" className="" width="150" />
            <h1 className="title">{t("musarium")}</h1>
            <h4 className="subtitle font-ligh">{t("key-word")}</h4>
            <img
              src={product_header}
              alt="img"
              className="img-header"
              // width="200"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeaderBanner;
