/* eslint-disable */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { HashLink as Link } from "react-router-hash-link";
import {
  Container,
  NavbarBrand,
  Navbar,
  Nav,
  NavItem,
  NavbarToggler,
  Collapse,
} from "reactstrap";

import logo from "../../assets/images/musarium/logo-navbar.png";
import TH from "../../assets/images/icon/flag-th.svg";
import EN from "../../assets/images/icon/flag-us.svg";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const { t, i18n, ready } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/

  return (
    <div className="topbar" id="top">
      <div className="header6">
        <Container className="po-relative">
          <Navbar className="navbar-expand-lg h6-nav-bar">
            <NavbarBrand href="/">
              <img src={logo} alt="wrapkit" width={150} />
            </NavbarBrand>
            <NavbarToggler onClick={toggle}>
              <span className="ti-menu"></span>
            </NavbarToggler>
            <Collapse
              isOpen={isOpen}
              navbar
              className="hover-dropdown font-14 justify-content-end"
              id="h6-info"
            >
              {/* <Nav navbar className="ms-auto">
                <NavItem>
                  <Link className="nav-link" to={"/"}>
                    Components
                  </Link>
                </NavItem>
                <NavItem>
                  <Link className="nav-link" to={"/custom-components"}>
                    Custom-Components
                  </Link>
                </NavItem>
              </Nav>
              <div className="act-buttons">
                <Link
                  to="/#coming"
                  className="btn btn-success-gradiant font-14"
                >
                  Upgrade To Pro
                </Link>
              </div> */}
              <div className="flag">
                <div
                  className="cursor-pointer"
                  onClick={() => changeLanguage("th")}
                >
                  <img src={TH} width="35" />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => changeLanguage("en")}
                >
                  <img src={EN} width="35" />
                </div>
              </div>
            </Collapse>
          </Navbar>
        </Container>
      </div>
    </div>
  );
};
export default Header;
