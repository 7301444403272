/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "reactstrap";
import ic_line from "../../assets/images/icon/line.png";
import ic_line_office from "../../assets/images/icon/line-office.png";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer4 b-t spacer bg-white">
      <Container>
        <Row>
          <Col lg="3" md="6" className="m-b-30">
            <h5 className="m-b-20">{t("label-address")}</h5>
            <p>{t("address")}</p>
          </Col>
          <Col lg="3" md="6" className="m-b-30">
            <h5 className="m-b-20">{t("label-phone")}</h5>
            <p>
              TEL : 080-863-0707
              <br />
            </p>
          </Col>
          <Col lg="3" md="6" className="m-b-30">
            <h5 className="m-b-20">{t("label-email")}</h5>
            <p>
              CTO :{" "}
              <a href="#" className="link">
                thammarat.k@psu.ac.th
              </a>{" "}
              <br />
              Site :{" "}
              <a href="#" className="link">
                musarium.company@gmail.com
              </a>
            </p>
          </Col>
          <Col lg="3" md="6">
            <h5 className="m-b-20">{t("label-social")}</h5>
            {/* <div className="grid gap-3"> */}
            <div className="flag">
              <img src={ic_line} width={30} height={30} />
              <p>Line ID: thamkmn</p>
            </div>
            <div className="flag">
              <img src={ic_line_office} width={30} height={30} />
              <p>Line Office ID: @musarium</p>
            </div>
            {/* </div> */}
          </Col>
        </Row>
        <div className="f4-bottom-bar">
          <Row>
            <Col md="12">
              <div className="m-t-10 m-b-10 copyright">
                Copyright © {new Date().getFullYear()}. Made with by{" "}
                <a
                  href="https://www.facebook.com/profile.php?id=61554523611257"
                  target="_blank"
                  rel="noopener"
                >
                  Do PRO Marketer Co., Ltd.
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default Footer;
