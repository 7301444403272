import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Container,
  Row,
  Col,
} from "reactstrap";

import cto from "../../../assets/images/musarium/Thammarat.jpg";

const BrandBackground = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="spacer" id="js-component">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h1 className="title font-bold">{t("brand-background")}</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="m-b-40">
          <Col md="6" xl="4" className="m-b-30 text-center">
            <img src={cto} alt="img" className="img-background img-rounded" />
          </Col>
          <Col md="6" xl="8" className="col-background" >
            <h6 className="subtitle-background">{t("des-brand-background")}</h6>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BrandBackground;
