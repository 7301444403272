import React from "react";
import { Container, Row, Col } from "reactstrap";
import { HashLink as Link } from "react-router-hash-link";
import Text from "../../assets/images/musarium/Text-keyword.png"

const CallToActionKeyWord = () => {
  return (
    <div className="bg-keyword" id="bg-keyword">
      <Container className="py-3 mt-5">
   
          <div className="bg-text-keyword">
            <img
              src={Text}
              alt="img"
              className="img-text"
            />
          </div>
      
      </Container>
    </div>
  );
};

export default CallToActionKeyWord;
